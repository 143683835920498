<template>
  <div class="w-full">
    <line-loader class="mb-8" />
    <line-loader class="mb-8" />
    <line-loader class="mb-8 h-24" />
  </div>
</template>

<script>
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'CreativeListByTypeLoader',
  components: { LineLoader },
};
</script>

<style scoped></style>
