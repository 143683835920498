var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("line-loader", { staticClass: "mb-8" }),
      _c("line-loader", { staticClass: "mb-8" }),
      _c("line-loader", { staticClass: "mb-8 h-24" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }