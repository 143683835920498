<template>
  <div>
    <div v-if="showCreateButton" class="flex justify-end mb-2">
      <div v-if="!allowCreateButton">
        <sun-popover
          text-info="Offer payment method must be 'CPA' and payment amount set by 'Percentage'"
          position="down"
          :delay-hide="0"
          :delay-show="0"
        >
          <sun-button
            :disabled="!allowCreateButton"
            variant="pill"
            class="custom-p-1 text-xs"
            @click="showNewDeepLinkCrea = true"
            >+ new creative
          </sun-button>
        </sun-popover>
      </div>
      <sun-button v-else variant="pill" class="custom-p-1 text-xs" @click="showNewDeepLinkCrea = true"
        >+ new creative
      </sun-button>
    </div>
    <component
      :is="creativeComponent"
      v-if="offer"
      :offer="offer"
      :creatives="creativesByType"
      :is-copy-available="true"
    ></component>
    <asterix-form-modal
      v-if="showNewDeepLinkCrea"
      title="New Deeplink creative"
      closable
      @submit="onFormSubmit"
      @cancel="closeModal"
    >
      <template #content>
        <form-row>
          <sun-label-group class="w-full" text="Title">
            <asterix-input
              id="name"
              v-model="creative.name"
              type="text"
              name="name"
              validate-on-blur
              :minlength="3"
              :maxlength="50"
              text-error="the field is required."
              placeholder="My amazing creative"
              required="required"
            />
          </sun-label-group>
        </form-row>
        <form-row>
          <url-creator v-if="creative" v-model="creative.targetUrl" class="w-full" label="Target URL" required />
        </form-row>
      </template>
      <template #footer>
        <creative-modal-footer :loading="modalLoading" @cancel="closeModal" />
      </template>
    </asterix-form-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import { USER } from '@/store/modules/auth/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { ROLES } from '@/model/shared/roles';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import UrlCreator from '@/components/molecules/shared/UrlCreator/UrlCreator';
import CreativeDeeplink from '@/model/modules/ecommerce/offer/creative/creativeDeeplink/CreativeDeeplink';
import { creatives as creativeService } from '@/services/modules/ecommerce/offer';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { getAllTrackerLinks } from '@/services/modules/ecommerce/offer/creatives/deeplink/getAllTrackerLinks';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'CreativeListSelector',
  components: {
    CreativeListBanner: () => import('@/components/organisms/modules/ecommerce/offer/creatives/CreativeListBanner'),
    CreativeListHtml: () => import('@/components/organisms/modules/ecommerce/offer/creatives/CreativeListHtml'),
    CreativeListDeeplink: () => import('@/components/organisms/modules/ecommerce/offer/creatives/CreativeListDeeplink'),
    CreativeListTextlink: () => import('@/components/organisms/modules/ecommerce/offer/creatives/CreativeListTextlink'),
    CreativeListFeed: () => import('@/components/organisms/modules/ecommerce/offer/creatives/CreativeListFeed'),
    CreativeModalFooter: () => import('@/components/molecules/shared/AsterixFooterModal'),
    AsterixFormModal,
    FormRow,
    AsterixInput,
    UrlCreator,
  },
  props: {
    type: {
      /** @type CreativeType */
      type: Object,
      required: true,
      validator: type => {
        return Object.keys(CREATIVE_TYPES).includes(type.id);
      },
    },
    /** @type: Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    publisherId: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      creative: new CreativeDeeplink(),
      allCreatives: null,
      showNewDeepLinkCrea: false,
      modalLoading: false,
      isLoadingLinkTracker: false,
    };
  },
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
    }),
    userRole() {
      return this.user.contextRoles.find(role => role.context === this.contextActive.id).role;
    },
    creativeComponent() {
      let component = null;

      switch (this.type?.id) {
        case CREATIVE_TYPES.BANNER.id:
          component = 'CreativeListBanner';
          break;
        case CREATIVE_TYPES.HTML.id:
          component = 'CreativeListHtml';
          break;
        case CREATIVE_TYPES.DEEPLINK.id:
          component = 'CreativeListDeeplink';
          break;
        case CREATIVE_TYPES.TEXTLINK.id:
          component = 'CreativeListTextlink';
          break;
        case CREATIVE_TYPES.FEED.id:
          component = 'CreativeListFeed';
          break;
      }
      return component;
    },
    creativesByType() {
      return this.allCreatives[this.type?.id] || [];
    },
    showCreateButton() {
      return this.type.id === CREATIVE_TYPES.DEEPLINK.id && this.userRole === ROLES.PUBLISHER.id;
    },
    allowCreateButton() {
      return this.offer?.priceType === 'CPA' && this.offer?.priceValueType === 'PERCENT';
    },
  },
  watch: {
    type: {
      deep: true,
      handler(newType) {
        if (newType.id === CREATIVE_TYPES.DEEPLINK.id) {
          this.callDeepLinkTracker();
        }
      },
    },
    publisherId() {
      this.callDeepLinkTracker();
    },
  },
  created() {
    this.allCreatives = deepClone(this.offer.creatives);
    if (this.offer && this.publisherId) {
      this.callDeepLinkTracker();
    }
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    closeModal() {
      this.showNewDeepLinkCrea = false;
      this.creative = new CreativeDeeplink();
    },
    async callDeepLinkTracker() {
      if (!this.publisherId || !this.offer) return null;
      this.isLoadingLinkTracker = true;
      const data = await getAllTrackerLinks(this.offer.id, this.publisherId);
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          const deeplinkCrea = this.allCreatives[CREATIVE_TYPES.DEEPLINK.id].find(crea => crea.id === key);
          deeplinkCrea.directLink = value;
        });
      }
      this.isLoadingLinkTracker = false;
    },
    async onFormSubmit({ valid }) {
      if (valid) {
        this.modalLoading = true;
        const createDeelLink = creativeService.deeplink.create;
        const { data: id } = await createDeelLink(this.offer?.id, this.creative);
        if (id) {
          this.creative.id = id;
          this.createToast(Toast.success('Creative created', `Creative created successfully!`));
        }
        this.modalLoading = false;
        this.showNewDeepLinkCrea = false;
        this.debounceUpdate();
      }
    },
    debounceUpdate: debounce(function () {
      this.$emit('update');
    }, 1000),
  },
};
</script>
