<template>
  <div class="creative-list-type flex flex-col p-6 bg-white rounded rounded-lg shadow-md lg:p-12 mb-12">
    <form-row v-if="hasCreatives && !userIsPublisher">
      <sun-label-group text="Select a publisher" class="w-full">
        <sun-popover
          class="w-full"
          text-info="The offer has no publishers yet"
          :trigger="!publisherOptionList.length ? 'hover click' : 'null'"
        >
          <sun-select
            v-model="selectedPublisher"
            class="w-full"
            label="name"
            track-by="id"
            :options="publisherOptionList"
            :disabled="isPublisherSelectDisabled"
          ></sun-select>
        </sun-popover>

        <div class="pl-4 flex items-center">
          <sun-popover :text-info="exportMessage" :trigger="!selectedPublisher ? 'hover click' : 'null'"
            ><sun-button
              variant="pill"
              class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
              :disabled="!selectedPublisher || isExportingCreatives"
              color="gray"
              :loading="isExportingCreatives"
              @click="exportCreatives"
              >Export All Creatives</sun-button
            ></sun-popover
          >
        </div>
      </sun-label-group>
    </form-row>

    <form-row>
      <div class="flex flex-col w-full">
        <sun-popover :text-info="exportMessage" :trigger="!selectedPublisher ? 'hover click' : 'null'"
          ><sun-button
            variant="pill"
            class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
            :disabled="!selectedPublisher || isExportingCreatives"
            color="gray"
            :loading="isExportingCreatives"
            @click="exportCreatives"
            >Export All Creatives</sun-button
          ></sun-popover
        >
        <sun-label-group text="Select a creative type" class="w-full">
          <sun-select v-model="creativeType" label="name" track-by="id" :options="creativesFormatted"></sun-select>
          <div v-if="userIsPublisher" class="pl-4 flex items-center"></div>
        </sun-label-group>
      </div>
    </form-row>

    <form-row class="w-full mt-5">
      <creative-list-selector
        v-if="areCreativesLoaded && creativeType"
        class="w-full"
        :type="creativeType"
        :offer="offer"
        :publisher-id="selectedPublisher.id"
        @update="$emit('update')"
      />
      <creative-list-by-type-loader v-else />
    </form-row>
  </div>
</template>

<script>
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import { deepClone } from '@/utils/deepClone';
import CreativeListSelector from '@/components/organisms/modules/ecommerce/offer/creatives/CreativeListSelector';
import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { getOfferCreativesJson } from '@/services/modules/ecommerce/offer/getOfferCreativesJson';
import CreativeListByTypeLoader from '@/components/atoms/Loaders/CreativeListByTypeLoader';

export default {
  name: 'CreativeListByType',
  components: {
    CreativeListByTypeLoader,
    FormRow,
    CreativeListSelector,
  },
  props: {
    /** @type: Offer */
    offer: {
      type: Object,
      default: () => null,
    },
    userIsPublisher: {
      type: Boolean,
      default: () => false,
    },
    defaultPublisher: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    creativeType: null,
    userInfo: null,
    selectedPublisher: null,
    isExportingCreatives: false,
  }),
  computed: {
    creatives() {
      return this.offer?.creatives || {};
    },
    areCreativesLoaded() {
      return !!this.offer?.creatives;
    },
    exportMessage() {
      return this.selectedPublisher
        ? 'Download all creatives from the selected publisher'
        : 'You need to select a publisher';
    },
    hasCreatives() {
      return !!Object.values(this.creatives).find(creativesByType => creativesByType?.length);
    },
    creativesFormatted() {
      return Object.values(deepClone(CREATIVE_TYPES)).map(type => {
        const count = this.creativeCount[type.id];

        if (count > 0) {
          const name = CREATIVE_TYPES[type.id].name;
          type.name = `${name} (${count})`;
        }

        return type;
      });
    },
    creativeCount() {
      const count = {};

      if (this.creatives) {
        Object.entries(this.creatives).forEach(([key, creas]) => {
          count[key] = creas?.length || 0;
        });
      }
      return count;
    },
    publisherOptionList() {
      return this.isUserPublisher ? this.userPublishers : this.offerPublishers;
    },
    userPublishers() {
      return this.userInfo?.publishers.filter(pub => this.offerPublishersIds.includes(pub.id)) || [];
    },
    isPublisherSelectDisabled() {
      return this.publisherOptionList && this.publisherOptionList?.length <= 1;
    },
    isUserPublisher() {
      return (
        this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.ECOMMERCE.id)?.role ===
        ROLES.PUBLISHER.id
      );
    },
    offerPublishers() {
      return this.offer?.publishers.map(p => p.publisher);
    },
    offerPublishersIds() {
      return this.offerPublishers.map(pub => pub.id);
    },
  },
  watch: {
    creativeType(newType) {
      if (!newType) {
        this.creativeType = this.creativesFormatted[0];
      }
    },
    selectedPublisher: {
      deep: true,
      handler(publisher) {
        if (this.creatives) {
          Object.keys(this.creatives).forEach(key => {
            this.creatives[key]?.forEach(crea => {
              this.$set(crea, 'publisher', publisher || null);
            });
          });
        }
      },
    },
    async offer() {
      await this.setDefaultPublisher();
    },
    defaultPublisher(publisher) {
      this.selectedPublisher = publisher.publisher;
    },
    async userInfo() {
      await this.setDefaultPublisher();
    },
  },
  async created() {
    this.creativeType = this.creativesFormatted[0];
  },
  methods: {
    async setDefaultPublisher() {
      if (!this.isUserPublisher) {
        this.selectedPublisher = this.offerPublishers[0] || null;
      }
    },
    async exportCreatives() {
      if (this.selectedPublisher && this.selectedPublisher.id && !this.isExportingCreatives) {
        this.isExportingCreatives = true;
        await getOfferCreativesJson(this.offer.id, this.selectedPublisher.id);
        this.isExportingCreatives = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-popover {
  @apply w-full block;
}
::v-deep .v-popover .trigger {
  display: block !important;
}
</style>
