var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showCreateButton
        ? _c(
            "div",
            { staticClass: "flex justify-end mb-2" },
            [
              !_vm.allowCreateButton
                ? _c(
                    "div",
                    [
                      _c(
                        "sun-popover",
                        {
                          attrs: {
                            "text-info":
                              "Offer payment method must be 'CPA' and payment amount set by 'Percentage'",
                            position: "down",
                            "delay-hide": 0,
                            "delay-show": 0,
                          },
                        },
                        [
                          _c(
                            "sun-button",
                            {
                              staticClass: "custom-p-1 text-xs",
                              attrs: {
                                disabled: !_vm.allowCreateButton,
                                variant: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showNewDeepLinkCrea = true
                                },
                              },
                            },
                            [_vm._v("+ new creative ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "sun-button",
                    {
                      staticClass: "custom-p-1 text-xs",
                      attrs: { variant: "pill" },
                      on: {
                        click: function ($event) {
                          _vm.showNewDeepLinkCrea = true
                        },
                      },
                    },
                    [_vm._v("+ new creative ")]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.offer
        ? _c(_vm.creativeComponent, {
            tag: "component",
            attrs: {
              offer: _vm.offer,
              creatives: _vm.creativesByType,
              "is-copy-available": true,
            },
          })
        : _vm._e(),
      _vm.showNewDeepLinkCrea
        ? _c("asterix-form-modal", {
            attrs: { title: "New Deeplink creative", closable: "" },
            on: { submit: _vm.onFormSubmit, cancel: _vm.closeModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "form-row",
                        [
                          _c(
                            "sun-label-group",
                            { staticClass: "w-full", attrs: { text: "Title" } },
                            [
                              _c("asterix-input", {
                                attrs: {
                                  id: "name",
                                  type: "text",
                                  name: "name",
                                  "validate-on-blur": "",
                                  minlength: 3,
                                  maxlength: 50,
                                  "text-error": "the field is required.",
                                  placeholder: "My amazing creative",
                                  required: "required",
                                },
                                model: {
                                  value: _vm.creative.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.creative, "name", $$v)
                                  },
                                  expression: "creative.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.creative
                            ? _c("url-creator", {
                                staticClass: "w-full",
                                attrs: { label: "Target URL", required: "" },
                                model: {
                                  value: _vm.creative.targetUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.creative, "targetUrl", $$v)
                                  },
                                  expression: "creative.targetUrl",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c("creative-modal-footer", {
                        attrs: { loading: _vm.modalLoading },
                        on: { cancel: _vm.closeModal },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1902904827
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }